@use '@/styles/utils/mixins.scss' as *;

.resourceSectionWrapper {
  .sectionHeadingContainer {
    display: flex;
    gap: 1.875rem;
    justify-content: space-between;

    @include tab() {
      flex-direction: column;
    }
  }

  .sectionDescription {
    max-width: 29rem;
  }

  .resourceContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 3rem;
  }

  .resourceList {
    margin-top: 6.75rem;
    max-width: 100%;

    .resourceCard {
      width: 23.75rem;
      height: 100%;
      border-radius: 1.25rem;
      -webkit-box-shadow: 0rem 0.375rem 1rem 0rem rgba($black, 0.12);
      -moz-box-shadow: 0rem 0.375rem 1rem 0rem rgba($black, 0.12);
      box-shadow: 0rem 0.375rem 1rem 0rem rgba($black, 0.12);
      padding: 2.5rem 1.875rem;
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 2.5rem;
      background-color: $white;
      border: 0.063rem solid $white;
      transition: all 0.2s ease-in-out;

      @include mob() {
        width: 100%;
      }

      &Body {
        display: flex;
        flex-direction: column;
        gap: 1.25rem;
        flex: 1 1 auto;
      }

      .description {
        color: $neutral08;
      }
    }

    :global {
      .swiper-wrapper {
        padding-bottom: 1.875rem;

        .swiper-slide {
          width: auto;
          margin: 0 1.25rem;
          height: auto;
          align-self: stretch;
          @include mob() {
            margin: 0;
          }
          &:first-child {
            margin-left: 2.5rem;
            @include mob() {
              margin-left: 0;
            }
          }
          &:last-child {
            margin-right: 2.5rem;
            @include mob() {
              margin-right: 0;
            }
          }
        }
      }
    }
  }

  .swipeWrapper {
    display: flex;
    justify-content: flex-end;

    .swiperIconBtn {
      display: flex;
      align-items: center;
      gap: 1.25rem;
      border: none;
      background-color: transparent;
      cursor: pointer;
      color: $primary;
      font-weight: 500;
      transition: all 0.2s ease-in-out;

      img {
        transition: all 0.2s ease-in-out;
      }

      &:hover,
      &:focus,
      &:active {
        color: $primaryAlt;

        img {
          opacity: 0.5;
        }
      }
    }
  }
}
